import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';
import { decodeBackend } from '@/assets/encode.js';

const ur = {
  activated: [],
  supportedDigitalTypes: [],
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    activated(state) { return state.activated; },
    supportedDigitalTypes(state) { return state.supportedDigitalTypes; },
    supportedDtMath(state) { return state.supportedDigitalTypes.filter(el => el.isMath == true); },
    supportedDtNonmath(state) { return state.supportedDigitalTypes.filter(el => el.isMath == false); }
  },
  actions: {
    fetch(context, pl) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'shareconf/load', pl).then(
          (response) => { 
            if (response.data.enc) {
              const decoded = decodeBackend(response.data.enc);
              context.commit('setActivated', decoded.activated);
              context.commit('setSupportedDigitalTypes', decoded.digital_tt);
            }
            resolve();
          },
          (error) => { 
            co("error", "fetchActivated", error);
            reject(error); 
          },
        );
      });
    },
    resetAll(context) {
      context.commit("resetAll");
    },
  },
  mutations: {
    setActivated(state, pl) { state.activated = pl; },
    setSupportedDigitalTypes(state, pl) { state.supportedDigitalTypes = pl; },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};