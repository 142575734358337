import { createStore } from "vuex";

import { co } from '@/assets/diagnostics.js';
// import { ax } from '@/assets/axios.js';
// import { decodeBackend } from '@/assets/encode.js';

//common
import authModule from './authModule.js';
import meModule from './meModule.js';
import users from './users.js';
import penModule from './penModule.js';
import questiontypeModule from './questiontypeModule.js';
import tresSingle from './tresSingle.js';
import ttSpecifics from './ttSpecifics.js';
import scaledscoresModule from './scaledscoresModule.js';
import serverConf from './serverConf.js';
// import supportedTests from './supportedTests.js';
import timezoneModule from './timezoneModule.js';

// erp only
import erpTestsets from './erp/erpTestsets.js';
import erpTestTakeAndEdit from './erp/erpTestTakeAndEdit.js';

// teacher only
import teacherTestModule from './teacher/teacherTestModule.js';

// student only
import testLocalSave from './student/testLocalSave.js';
import ttStudent from './student/ttStudent.js';
import mytestsModule from './student/mytestsModule.js';
import testtake from './student/testtake.js';
import vocabModule from "./student/vocabModule.js";

const ur = {
  blockKeyboardListener: false,
};

let storeModules = {
  // common
  auth: authModule,
  me: meModule,
  users,
  pen: penModule,
  questiontypes: questiontypeModule,
  tresSingle,
  ttSpecifics,
  scaledscores: scaledscoresModule,
  serverConf,
  // supportedTests,
  tz: timezoneModule,  
};

// add stores depending on app target
if (process.env.VUE_APP_TARGET === "student") { 
  storeModules = {
    ...storeModules,
    testLocalSave,
    ttStudent,
    mytests: mytestsModule,
    testtake,
    vocab: vocabModule,
  };
}

// separate teachers and erp later
else if (process.env.VUE_APP_TARGET === "teacher" ||
  process.env.VUE_APP_TARGET === "erp"
) {
  storeModules = {
    ...storeModules,
    // erp
    erpTestsets,
    erpTestTakeAndEdit,

    // teacher
    teacherTest: teacherTestModule,
  };
}

const store = createStore({
  modules: storeModules,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    blockKeyboardListener(state) { return state.blockKeyboardListener; },
  },
  actions: {
    setBlockKeyboardListener(context, pl) { 
      context.commit('setBlockKeyboardListener', pl); 
    },
    resetAllStores(context) {
      //common
      context.dispatch('me/resetAll');
      context.dispatch('ttSpecifics/resetAll');
      context.dispatch('pen/resetAll');
      context.dispatch('serverConf/resetAll');
      context.dispatch('resetAll');

      try {
        // erp only
        if (this.hasModule('erpTestsets/resetAll'))
          context.dispatch('erpTestsets/resetAll');
        if (this.hasModule('erpTestTakeAndEdit/resetAll'))
          context.dispatch('erpTestTakeAndEdit/resetAll');
  
        // teacher only
        if (this.hasModule('teacherTest/resetAll'))
          context.dispatch('teacherTest/resetAll');
  
        // student only
        if (this.hasModule('testLocalSave/resetAll'))
          context.dispatch('testLocalSave/resetAll');
        if (this.hasModule('ttStudent/resetAll'))
          context.dispatch('ttStudent/resetAll');
        if (this.hasModule('mytests/resetAll'))
          context.dispatch('mytests/resetAll');
        if (this.hasModule('vocab/resetAll'))
          context.dispatch('vocab/resetAll');
      } catch(e) {
        co('warning', 'resetAllStores', e);
      }

      co('dark', 'reset all stores called');
    },
    resetAll(context) {
      context.commit("resetAll");
    },
  },
  mutations: {
    setBlockKeyboardListener(state, pl) { state.blockKeyboardListener = pl; },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
});

export default store;